import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MeetTheHostNav from "../components/meet-the-host-nav";

const MeetTheHostPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | Meet the Senior Producer"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>Meet the Senior Producer</h1>
      </div>
    </section>
    <section className="section bg-gray about-the-program-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <MeetTheHostNav />
          </div>
          <div className="col-lg-8">
            <p>
              Heather Date serves as vice president for communications and
              engagement at University of Maryland Global Campus (UMGC). Based
              in the Office of the President, Heather is focused on
              strengthening internal communications and fostering employee
              engagement. She joined UMGC in 2012 as associate vice president
              for communications, helping build a communications operation for
              the university that includes executive communications, media
              relations, social media, university events, Commencement Services,
              and special projects.
            </p>
            <p>
              Heather began her career working on public affairs programming in
              the Washington, D.C. Bureau of CNN, before joining the media
              practice of the public relations firm Hill &amp; Knowlton as a
              senior account executive. She returned to her alma mater, George
              Washington University, as coordinating producer for the
              school&#39;s five-year partnership with CNN. The partnership
              resulted in the production of more than 750 programs, including
              the daily political debate program <em>Crossfire</em>, broadcast
              live before studio audiences. She also served as associate
              director of the university&#39;s Global Media Institute and, since
              2007, as senior producer of the public broadcasting series,{" "}
              <em>The Kalb Report</em>, moderated by legendary journalist Marvin
              Kalb.
            </p>
            <p>
              Heather earned her bachelor’s degree in political communication
              from George Washington University’s School of Media and Public
              Affairs, where she received the Dorothy and Will Roberts prize for
              academic excellence, professional promise, and community service.
              In 2011, she was honored in Beverly Hills, California, by the
              Alliance for Women in Media with a Gracie Award for Outstanding
              Producer— News/Non-Fiction for her work on The Kalb Report.
            </p>
          </div>
          <div className="col-lg-3 offset-lg-1" />
        </div>
      </div>
    </section>
  </Layout>
);

export default MeetTheHostPage;
